@charset "utf-8";
.flex-container {
  display: flex;
  justify-content: space-between;
  &.flex-reverse {
    flex-direction: row-reverse;
    align-items: stretch;
  }
  .flex-item {
    &.w-main {
      width: 654px;
      padding-left: 26px;
      box-sizing: border-box;
    }
    &.w-sub {
      width: 306px;
    }
    &.right-line {
      border-right: 1px solid #eee;
    }
    &.left-line {
      border-left: 1px solid #eee;
    }
  }
}

.l-footer {
  background: #4c4c4c;
  color: #fff;
  a {
    color: #fff;
    text-decoration: none;
    &:hover {
      opacity: 0.7;
    }
  }
  &__inner {
    padding: 25px 20px 30px;
    overflow: hidden;
  }
  &__menu {
    float: left;
    & + .l-footer__menu {
      margin-left: 60px;;
    }
    &-content {
      display: flex;
      justify-content: space-between;
      overflow: hidden;
    }
  }
  &__menu-title {
    font-weight: normal;
    margin: 0 0 5px;
    font-size: 14px;
  }
  &__list {
    flex-grow: 1;
    & + .l-footer__list {
      margin-left: 40px;
    }
    li {
      font-size: 12px;
      margin: 0 0 2px;
    }
  }
  .copyright {
    color: #fff;
    font-size: 10px;
    text-align: center;
    background: #363634;
    line-height: 2;
    @include font-EN_m;
  }
  .pagetop {
    display: block;
    bottom: 20px;
    height: 46px;
    line-height: 46px;
    position: fixed;
    right: 20px;
    width: 46px;
    z-index: $z_pagetop;
    @include ov;
    &:hover {
      opacity: 0.7;
    }
  }
}

@media(max-width: $breakpoint-s) {
  .flex-container {
    display: block;
  }
  .l-footer {
    &__inner {
      padding: 25px 10px 30px;
    }
    &__menu {
      float: none;
      & + .l-footer__menu {
        margin: 20px 0 0 0;
      }
      &-content {
        width: 100%;
      }
    }
    &__list {
      & + .l-footer__list {
        margin-left: 0;
      }
    }
  }
}
