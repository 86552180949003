@charset "utf-8";
$ccnl_bl:#008dc7; //テキストリンク
$ccnl_g:#8ac013; //重要ボタン
$ccnl_p:#e61874; //アクセント・強調
$ccnl_er:#ff0000; //エラー専用
$ccnl_b0:#f4f2f0; //ベース
$ccnl_be1:#dfdcd9; //ベース濃
$ccnl_ico:#c7c7c7; //アイコン用
$ccnl_gr0:#999999; //グレー淡
$ccnl_gr1:#666666; //グレー
$ccnl_gr2:#333333; //グレー濃
$ccnl_bk:#000000; //黒

// Responsive design breakpoints
$breakpoint-l: 960px;
$breakpoint-m: 768px;
$breakpoint-s: 632px;
$breakpoint-ss: 374px;

//@include clearfix;
@mixin clearfix {
  *zoom: 1;
  &:after {
    content: ".";
    display: block;
    clear: both;
    height: 0;
    visibility: hidden;
  }
}

//@include link;
@mixin link {
  a {
    color: #008dc7;
  }
  a:visited {
    color: #0072a1;
  }
  a:hover {
    color: #00a0db;
    text-decoration: underline;
  }
  a:active {
    color: #0072a1;
    text-decoration: underline;
  }
}

// @include ov;
@mixin ov {
  -moz-transition: all .4s ease-in-out;
  -webkit-transition: all .4s ease-in-out;
  transition: all .4s ease-in-out;
}

// @include ovImg;
@mixin ovImg {
  background: #fff;
  opacity: 1;
  -moz-transition: opacity .4s ease-in-out;
  -webkit-transition: opacity .4s ease-in-out;
  transition: opacity .4s ease-in-out;
  &:hover {
    opacity: 0.6;
  }
}

//@include font-EN;
@import "https://fonts.googleapis.com/css?family=Roboto:900,400,300";
%font-EN {
  font-family: 'Roboto', sans-serif;
}
@mixin font-EN_f {
  @extend %font-EN;
  font-weight: 300;
}
@mixin font-EN_m {
  @extend %font-EN;
  font-weight: 400;
}
@mixin font-EN_b {
  @extend %font-EN;
  font-weight: 900;
}

//@include font-JP;
@mixin font-JP {
  font-family: "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
}

//@include PCsiteWidth-user;
@mixin PCsiteWidth-user {
  margin: 0 auto;
  width: 100%;
  max-width: 960px;
}

//@include PCsiteWidthMax;
@mixin PCsiteWidthMax {
  margin: 0 auto;
  width: 1048px;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

//@include position-vAlign-center;
@mixin position-vAlign-center {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

//@include position-hAlign-center;
@mixin position-hAlign-center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

//@include position-VH-center;
@mixin position-VH-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

//@include lineClamp;
@mixin lineClamp($line-count, $line-height: 1.6, $color: #333, $backround-color: #fff) {
  overflow: hidden;
  position: relative;
  display: inline-block;
  line-height: $line-height;
  max-height: #{$line-height * $line-count}em;
  text-align: justify;
  padding-right: 1em;
  width: 100%;
  &:before {
    content: "…";
    position: absolute;
    right: 0;
    bottom: 0;
    color: inherit;
  }
  &:after {
    content: "";
    position: absolute;
    right: 0;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    background: $backround-color;
  }
}

@mixin overlay($z-index) {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(102, 102, 102, 0.8);
  z-index: $z-index;
}

//z-index

/* lawyer content 10-100 */
$z_lawyer_edit-menu-tab__popup:10;

/* user content 10-100 */
$z_user_l-awrap__head__lawyer__popup: 10;
$z_user_l-main__tab-menu__popup:12;
$z_user_l-mainVisual__ranking__list:13;
$z_user_l-mainVisual__avatar:2;
$z_user_l-mainVisual__mylistArea:3;
$z_user_main-visual__title__text-line__inner:14;
$z_user_l-main__module__focus__request__popup:15;

/* lawer_layout 100-300 */
$z_lawyer_l-header: 100;
$z_lawyer_l-header__popup: 110;
$z_lawyer_submenu__overlay:120;
$z_lawyer_submenu:121;
$z_lawyer_submenu__opener:122;
$z_lawyer_pagetop:300;

/* user_layout 100-300 */
$z_user_l-header: 100;
$z_user_l-header__popup: 110;
$z_user_l-sub__searh: 8000; // NOTE: 暫定値（将来的にはv2に移行する為不要になる）

/* content コンテンツ上に表示 200-500 */
$z_c-flash_msg:200;
$z_pagetop:205;
$z_c-loading:210;
$z_c-modal:300;
