@charset "utf-8";
%btn-base {
  display: inline-block;
  position: relative;
  padding: 1rem 1.5rem;
  border: none;
  vertical-align: middle;
  text-align: center;
  box-sizing: border-box;
  border-radius: 3px;
  font-size: 16px;
  cursor: pointer;
  min-height: 45px;
  @include ov;
  &:disabled {
    opacity: .5;
  }
  .icon {
    font-size: 22px;
    margin: 0 6px 0 0;
    vertical-align: middle;
    line-height: 1.1;
  }
}
%btn__wrap-base {
  display: flex;
  align-items: stretch;
}

.btn {
  @extend %btn-base;
  &__wrap {
    @extend %btn__wrap-base;
    &-center {
      @extend %btn__wrap-base;
      justify-content: center;
    }
    &-between {
      @extend %btn__wrap-base;
      justify-content: space-between;
    }
    &-inline-block {
      display: inline-block;
      vertical-align: middle;
    }
  }
  & + .btn {
    margin-left: .5rem;
  }
  // 基本サイズ
  &-s {
    padding: .15rem .5rem;
    font-size: 11px;
    min-height: auto;
  }
  &-m {
    font-size: 14px;
    .icon {
      font-size: 18px;
    }
  }
  &-l {
    padding: .75rem 1.2rem;
    font-size: 18px;
    .icon {
      font-size: 26px;
    }
  }
  // 横幅調整
  &-block {
    width: 100%;
  }
  &-wide-s {
    width: 150px;
  }
  &-wide-m {
    width: 200px;
  }
  &-wide-l {
    width: 300px;
  }
  // ボタンタイプ
  &.btn-primary {
    background: $color-deep-blue-800;
    color: #fff;
    &:hover {
      opacity: .75;
    }
    &:active {
      transition: opacity 0s ease-in-out;
    }
  }
  &.btn-secondary {
    background: #fff;
    color: #666;
    border: 1px solid #dfdcd9;
    &:hover {
      background: #fff;
      color: #28a7e1;
      border: 1px solid #28a7e1;
    }
    &:active {
      transition: all 0s ease-in-out;
    }
    .icon {
      color: #28a7e1;
      font-style: normal;
    }
    &.is-on {
      box-shadow: inset 0px 2px 0px rgba(#c2e8f7, 1);
      border: #28a7e1;
      color: #666666;
      background: #d3f3ff;
    }
  }
  &.btn-cancel {
    background: #999;
    color: #fff;
    &:hover {
      background: #c7c7c7;
    }
    &:active {
      background: #838383;
      transition: all 0s ease-in-out;
    }
  }
  &.btn-important {
    background: #e61874;
    color: #fff;
    text-align: center;
    &:hover {
      background: #ff3891;
    }
    &:active {
      background: #c7226c;
      transition: all 0s ease-in-out;
    }
  }
  // その他
  &-bold {
    font-weight: 700;
    .icon {
      vertical-align: top;
    }
  }
  &-icon-center {
    .icon {
      display: block;
      margin: auto;
    }
  }
}


.c-btn-long {
  a {
    border: 1px solid #ccc;
    padding: 8px 0;
    font-size: 14px;
    text-align: center;
    border-radius: 3px;
    display: block;
    color: #666666;
    font-weight: bold;
    @include ov;
    &:hover {
      color: #28a7e1;
      border: 1px solid #28a7e1;
      .icon {
        color: #28a7e1;
        @include ov;
      }
    }
  }
  .icon {
    color: #c7c7c7;
    font-weight: normal;
  }
  &--fw_normal {
    a {
      font-weight: normal;
    }
  }
}
.c-text-new {
  color: #e3066c;
  font-size: 12px;
  @include font-EN_m;
}

@media(max-width: $breakpoint-s) {
  .btn {
    &-wide-s {
      width: 100%;
      max-width: 150px;
    }
    &-wide-m {
      width: 100%;
      max-width: 200px;
    }
    &-wide-l {
      width: 100%;
      max-width: 300px;
    }
  }
}
