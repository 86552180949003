@charset "utf-8";
body {
  color: #333;
  font-size: 14px;
  font-family: "HiraKakuProN", "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  line-height: 1.6;
  word-wrap: break-word;
  word-break: break-all;
  overflow-x: hidden;
}

html>body {
  height: auto;
  min-width: 320px;
}

html,
body {
  &.is-prevent-scroll {
    height: 100%;
    overflow: hidden;
  }
}

#content {
  width: 100%;
  overflow: hidden;
}

a {
  outline: none;
  -webkit-touch-callout: none;
  color: #008dc7;
  text-decoration: none;
}

a:focus,
*:focus {
  outline: none;
}

a img {
  vertical-align: top;
  border: none;
  border-style: none;
}

body {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p {
  margin: 0 0 1em;
}

ul,
li {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

img {
  vertical-align: top;
}

a {
  text-decoration: none;
}

span.indent {
  display: none;
}

noscript.noscript {
  position: fixed;
  width: 100%;
  display: block;
  padding: 5px;
  text-align: center;
  background: rgba(yellow, .7);
  font-size: 20px;
  font-weight: bold;
  z-index: 10001;
}

.tablecol {
  display: table;
  &__cell {
    display: table-cell;
    &.col6 {
      width: 50%;
    }
  }
}

.nl2br {
  white-space: pre-wrap;
  word-wrap: break-word;
}

//★font icon
.entypo-icon-right-open-mini:before {
  content: "\e762";
}

.clearfix {
  @include clearfix;
}

.overflow-hidden {
  overflow: hidden;
}

// Rails debug dump stlyle
.debug_dump {
  display: block;
  padding: 9.5px;
  margin: 0 0 10px;
  font-size: 13px;
  line-height: 1.42857;
  word-break: break-all;
  word-wrap: break-word;
  color: #333333;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
}

