/* ==========================================================================
   TODO: 現在ベタ書きされているカラーコードをココナラ本体のカラーパレットの変数に置き換える
   ========================================================================== */

// white
// #ffffff;

// black
// #000000;

// gray
// #f5f5f5;
// #eeeeee;
// #d2d2d2;
// #cccccc;
// #cdcdcd;
// #c7c7c7;
// #bbbbbb;
// #b4b4b4;
// #b2b2b2;
// #999999;
// #838383;
// #7a7a7a;
// #777777;
// #666666;
// #4c4c4c;
// #363634;
// #333333;

// blue
// #eff6fa;
// #d3f3ff;
// #66c6e9;
// #55c0e7;
// #45b3e5;
// #2aa2e1;
// #28a7e1;
// #11a6dd;
// #00a0db;
// #008dc7;
// #0072a1;

// red
// #fff0f7;
// #f5b6d2;
// #e61874;
// #e3066c;
// #ff0000;

// green
// #9cd416;
// #8ac013;
// #77a80b;

// beige
// #f9f8f7;
// #f4f2f0;
// #f3f2f0;
// #e3e0d5;
// #cbc09c;
// #88827d;

// other
// #dfdcd9;
// #dbd6d2;

// category colors
// #cb7698;
// #cc7676;
// #c6916d;
// #beac65;
// #a9ba65;
// #73b873;
// #76cc99;
// #75c0b1;
// #76bbcc;
// #84a1cc;
// #8f8fc9;
// #c490d1;
// #ca89bd;
// #d8d8d8;


/* ==========================================================================
   color

   ※ 各色相毎に10段階
   ※ 面倒なのでカテゴリ色以外はココナラ本体のカラーパレットを使う
   ========================================================================== */

/* black
   ----------------------------------------------------------------- */
$color-black : #000;

/* white
   ----------------------------------------------------------------- */
$color-white : #fff;

/* gray
   ----------------------------------------------------------------- */
$color-gray-50  : #f3f3f3;
$color-gray-100 : #eee;
$color-gray-200 : #ddd;
$color-gray-300 : #ccc;
$color-gray-400 : #bbb;
$color-gray-500 : #aaa;
$color-gray-600 : #999;
$color-gray-700 : #666;
$color-gray-800 : #4c4c4c;
$color-gray-900 : #333;

/* beige
   ----------------------------------------------------------------- */
$color-beige-50  : #f4f2f0;
$color-beige-100 : #e8e3df;
$color-beige-200 : #ded6d1;
$color-beige-300 : #d7cec7;
$color-beige-400 : #c9bdb5;
$color-beige-500 : #bcada2;
$color-beige-600 : #a99b91;
$color-beige-700 : #968a82;
$color-beige-800 : #7a7169;
$color-beige-900 : #5e5651;

/* blue
   ----------------------------------------------------------------- */
$color-blue-50  : #f0f9fd;
$color-blue-100 : #e1f3fb;
$color-blue-200 : #bae3f5;
$color-blue-300 : #94d3f0;
$color-blue-400 : #62bfe9;
$color-blue-500 : #28a7e1;
$color-blue-600 : #2496ca;
$color-blue-700 : #1e7da9;
$color-blue-800 : #186487;
$color-blue-900 : #124b65;

/* deep blue
   ----------------------------------------------------------------- */
$color-deep-blue-50  : #e6f0f8;
$color-deep-blue-100 : #d4e6f3;
$color-deep-blue-200 : #adcee8;
$color-deep-blue-300 : #80b4db;
$color-deep-blue-400 : #599dd0;
$color-deep-blue-500 : #267fc2;
$color-deep-blue-600 : #0068b7;
$color-deep-blue-700 : #00599c;
$color-deep-blue-800 : #004980;
$color-deep-blue-900 : #003964;

/* indigo blue
   ----------------------------------------------------------------- */
$color-indigo-blue-50  : #e7e9f6;
$color-indigo-blue-100 : #d5d9f0;
$color-indigo-blue-200 : #b0b6e2;
$color-indigo-blue-300 : #848dd2;
$color-indigo-blue-400 : #5e6bc4;
$color-indigo-blue-500 : #081ba5;
$color-indigo-blue-600 : #071894;
$color-indigo-blue-700 : #061684;
$color-indigo-blue-800 : #061373;
$color-indigo-blue-900 : #051063;

/* green
   ----------------------------------------------------------------- */
$color-green-50  : #f1f8e4;
$color-green-100 : #ddedbc;
$color-green-200 : #c7e18f;
$color-green-300 : #b0d563;
$color-green-400 : #9fcc40;
$color-green-500 : #8ec31f;
$color-green-600 : #80af1c;
$color-green-700 : #729c19;
$color-green-800 : #638816;
$color-green-900 : #4e6b11;

/* deep green
   ----------------------------------------------------------------- */
$color-deep-green-50  : #e6f6e6;
$color-deep-green-100 : #c1eac1;
$color-deep-green-200 : #97db97;
$color-deep-green-300 : #6ecd6d;
$color-deep-green-400 : #4ec24d;
$color-deep-green-500 : #2fb72e;
$color-deep-green-600 : #2aa429;
$color-deep-green-700 : #269225;
$color-deep-green-800 : #218020;
$color-deep-green-900 : #1a6419;

/* pink
   ----------------------------------------------------------------- */
$color-pink-50  : #fdedf4;
$color-pink-100 : #fbdae9;
$color-pink-200 : #f8bad6;
$color-pink-300 : #f38cba;
$color-pink-400 : #ec5297;
$color-pink-500 : #e61874;
$color-pink-600 : #d6166c;
$color-pink-700 : #b8135d;
$color-pink-800 : #a11151;
$color-pink-900 : #730c3a;

/* purple
   ----------------------------------------------------------------- */
$color-purple-50  : #eee8ef;
$color-purple-100 : #dcd1de;
$color-purple-200 : #c1afc5;
$color-purple-300 : #a78cac;
$color-purple-400 : #95759b;
$color-purple-500 : #7a5383;
$color-purple-600 : #683b72;
$color-purple-700 : #4e1959;
$color-purple-800 : #461650;
$color-purple-900 : #36113e;

/* red purple
   ----------------------------------------------------------------- */
$color-red-purple-50  : #f8e9f3;
$color-red-purple-100 : #f1d3e6;
$color-red-purple-200 : #e3a6cd;
$color-red-purple-300 : #dc90c1;
$color-red-purple-400 : #d16eae;
$color-red-purple-500 : #c34295;
$color-red-purple-600 : #b82183;
$color-red-purple-700 : #931a69;
$color-red-purple-800 : #80175b;
$color-red-purple-900 : #651248;

/* red
   ----------------------------------------------------------------- */
$color-red-50  : #fcd9d9;
$color-red-100 : #fab3b3;
$color-red-200 : #f78080;
$color-red-300 : #f45959;
$color-red-400 : #f12626;
$color-red-500 : #ee0000;
$color-red-600 : #d60000;
$color-red-700 : #be0000;
$color-red-800 : #a60000;
$color-red-900 : #830000;

/* orange
   ----------------------------------------------------------------- */
$color-orange-50  : #ffefdb;
$color-orange-100 : #fee4c2;
$color-orange-200 : #fed49e;
$color-orange-300 : #fec47a;
$color-orange-400 : #fda93d;
$color-orange-500 : #fd940d;
$color-orange-600 : #e3850c;
$color-orange-700 : #be6f0a;
$color-orange-800 : #be6f0a;
$color-orange-900 : #8b5107;

/* yellow
   ----------------------------------------------------------------- */
$color-yellow-50  : #fff9db;
$color-yellow-100 : #fff6c3;
$color-yellow-200 : #ffed87;
$color-yellow-300 : #ffe96f;
$color-yellow-400 : #ffe13a;
$color-yellow-500 : #ffda0f;
$color-yellow-600 : #e5c40d;
$color-yellow-700 : #bfa30b;
$color-yellow-800 : #998309;
$color-yellow-900 : #665706;

/* gold
   ----------------------------------------------------------------- */
$color-gold-50  : #f0eadd;
$color-gold-100 : #e0d5bb;
$color-gold-200 : #cbb88d;
$color-gold-300 : #bba36b;
$color-gold-400 : #a6863d;
$color-gold-500 : #97711b;
$color-gold-600 : #886518;
$color-gold-700 : #795a16;
$color-gold-800 : #694f13;
$color-gold-900 : #5b4410;

/* category color
   ----------------------------------------------------------------- */
$color-category-1  : #cb7698;
$color-category-2  : #cc7676;
$color-category-3  : #c6916d;
$color-category-4  : #beac65;
$color-category-5  : #a9ba65;
$color-category-6  : #73b873;
$color-category-7  : #76cc99;
$color-category-8  : #75c0b1;
$color-category-9  : #76bbcc;
$color-category-10 : #84a1cc;
$color-category-11 : #8f8fc9;
$color-category-12 : #a084c8;
$color-category-13 : #c490d1;
$color-category-14 : #c084b5;
$color-category-15 : #999999;
