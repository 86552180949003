@charset "utf-8";
body.error-Page {
  .l-container {
    min-height: 100%;
    position: relative;
    height: auto !important;
    height: 100%;
  }
  .l-error {
    text-align: center;
    padding: 42px 0 0;
    min-height: 450px;
    .subtitle {
      font-family: 'Roboto', sans-serif;
      font-size: 20px;
      margin: 0;
    }
    .maintitle {
      font-size: 20px;
      margin: 0 0 25px;
    }
    .pic {
      margin: 0 0 30px;
    }
    .text {
      margin: 0 0 25px;
    }
    .btn-top {
      width: 304px;
      margin: 0 auto;
      a {
        width: 304px;
      }
    }
  }
}
